<template>
  <div class="pageContainer">
    <b class="w-100 text-center graph-title">Battery Data Graph</b>
    <div class="chartContainerStyle">
      <DataChartPie :chart-data="batteryChartData" :options="batteryChartOptions" :styles="chartStyle"/>
    </div>
    <div class="measTimeDiv" v-if="batteryDataRdy">
      <p>
        Batteriestand gemessen um<br>
        {{batteryChartValues.ts.slice(-1)[0]}}
      </p>
      <b-button class="restart-button" @click="doReboot()">Neu starten</b-button>
    </div>
  </div>
</template>

<script>
import DataChartPie from '../components/DataChartPie.js'

export default {
  name: "DataGraphBattery",
  components: {
    DataChartPie
  },

  data () {
    return {
      chartStyle: {
        height: '100%',
        width: '95%',
        margin: '0',
        padding: '0'
      },

      batteryChartOptions: {
        title: {
          display: true,
          text: "Batteriestand in %",
          fontColor: '#2c3e50',
          fontSize: '14'
        },

        legend: {
          display: true,
          labels: {
            fontColor: '#2c3e50',
            /*fontSize: "14"*/
          }
        },

        responsive: true,
        maintainAspectRatio: false,
      },

      batteryChartData: {
        labels: ['Batteriestand', 'Entladen'],
        datasets: [{
          backgroundColor: ['green', 'black'],
          data: [66, 34]
        }]
      },

      batteryChartValues: null,
      batteryDataRdy: false,

      timer: null,      
    }
  },

  mounted() {
    if(this.$store.state.sBatteryAvailable) {
      this.loadData()
      this.timer = setInterval(this.loadData, 10000)
    }
  },

  methods: {
    loadData() {
      this.batteryChartValues = this.$store.state.batData
      let charge = this.batteryChartValues.lvl.slice(-1)[0]
      let notCharge = 100 - charge

      this.batteryChartData = {
        labels: ['Batteriestand', 'Entladen'],
        datasets: [{
          backgroundColor: ['green', 'black'],
          data: [charge, notCharge]
        }]
      }

      if(!this.batteryDataRdy) this.batteryDataRdy = true
    },

    cancelUpdates() {
      this.batteryDataRdy = false
      clearInterval(this.timer)
    },

    doReboot() {
      if(this.$store.state.sBatteryAvailable) {
        const buf = new ArrayBuffer(1)
        let doReboot = new Uint8Array(buf)
        doReboot[0] = 0b00000010 // 0x01 = hutdown; 0x02 = reboot 

        this.$store.state.batHandles.shutdownReboot.writeValue(doReboot)
        console.log("Restarting device")
      } else {
        console.log("Service battery is not available!")
      }
    }

  }, // methods:

  beforeDestroy() {
    this.cancelUpdates()
  }

}
</script>

<style scoped>
.pageContainer {
  width: 100%;
  height: 100%;
  font-size: 0px;
}
.graph-title {
  font-size: 18px;
}
.chartContainerStyle {
  display: inline-block;
  height:  50%;
  width: 95%;
  padding-bottom: 10px;
}
.measTimeDiv {
  font-size: 15px;
}
.restart-button {
  background-color: #2c3e50;
}
</style>